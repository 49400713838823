.modalWrap {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    overflow-x: hidden;
    background-color: rgba(30, 43, 30, .75);
    pointer-events: none;
    opacity: 0;
    transition: opacity 125ms 350ms ease;
}

.visible {
    pointer-events: auto;
    opacity: 1;
    transition: all 150ms ease-in-out;
}

.modal {
    overflow-x: hidden;
    position: relative;
    display: block;
    width: 40%;
    min-height: 100px;
    min-width: 300px;
    margin: 20px auto;
    padding-bottom: 10px;
    background-color: #fff;
    align-self: center;
    opacity: 0;
    transition: opacity 125ms 125ms ease, transform 150ms 125ms ease;
    transform: scale(0);
    font-size: 1.2em;
}

.visible .modal {
    opacity: 1;
    transform: scale(1);
    transition: opacity 125ms 250ms ease, transform 175ms 250ms ease;
}

.heading {
    height: 15px;
    justify-content: flex-end;
}

.modalContent {
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px
}

.modalContent button {
  padding: 7px 38px;
  border: 0;
  border-radius: 24px;
  background-color: #02390B;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.modalContent p {
    text-align: center;
    font-size: 23px;
    color: black;
    font-weight: 600;
}

.modalHeadingMessage {
    font-size: 60px;
    color: white;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.success {
    background-color: #02390B;
}

.danger {
    background-color: #d11a2a;
}

@media screen and (max-width:1000px) {
    .modalContent {
        padding: 10px;
    }

    .modalContent p {
        font-size: 18px;
    }

    .modalContent button {
        padding: 5px 15px;
        color: white;
        min-width: auto;
        font-size: 16px;
    }
}
