.headerWrapper {
    flex-shrink: 0;
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
}

 @media screen and (min-width: 1024px){
    .headerWrapper {
        position: relative;
    }
} 