.TableRowMobile {
    display: flex;
    border: 1px solid black;
    padding: 10px;
    color: #000;
}

.TableRowMobile:hover {
    background-color: #d9dbe1;
    color: #000;
}

.TableRowMobile + .TableRowMobile {
    border-top: 0;
}

.TableMobile {
    display: none;
    font-family: "Poppins", sans-serif;
}

.TableItemWrapperHeadingMobile,
.TableItemWrapperMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
}

.TableItemWrapperHeadingMobile {
    width: 40%;
}

a:hover {
    text-decoration: none;
}


.TableHeader {
    display: table-row;
    border: 1px black solid;
}

.TableBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    display: table-row-group;
}

.TableItemWrapper {
    position: relative;
    display: table-row;
    height: 70px;
}

.TableItemWrapper:hover {
    background-color: #d9dbe1;
}

.TableItem {
    margin-bottom: 5px;
    word-break: break-word;
    word-wrap: break-word;
    display: table-cell;
    text-align: center;
    padding: 5px 20px;
    color: black;
    vertical-align: middle;
    font-size: 18px;
}

.Table {
    display: table;
    border-collapse: collapse;
    width: 100%;
}

.TableHeaderItem {
    display: table-cell;
    text-align: center;
    font-weight: bold;
    padding: 20px;
    vertical-align: middle;
    border: 1px solid black;
}

@media screen and (max-width: 600px) {
    .TableMobile {
        display: block
    }

    .Table {
        display: none;
    }
}

.container {
    margin-bottom: 40px;
}
