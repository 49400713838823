.profileHeading{
    text-align: center;
    padding: 20px 0px;
}

.recoveryForm{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 15px -5px rgb(34 60 80 / 57%);
    margin: 10px;
    padding: 40px 10px;
    background-color: white;
}

.profileFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}
.profileFlex span{
    flex-basis: 10%;
}

.recovery{
    margin: 15px 0px;
}
.recovery span{
    flex-basis: 75px;
}
.recoveryText{
    text-align: center;
}
.recoveryHeading{
    text-align: center;
    margin-bottom: 20px;
}
.recoveryInput{
    flex-grow: 1;
}
.recoveryBtn{
    width: 280px;
    margin: 0 auto;
}

.profileBtn{
    padding: 10px 15px;
    border-radius: 5px;
    background: #50802C;
    color: white;
    font-size: 16px;
}
.profileBtn:hover{
    filter: brightness(80%);
}

@media screen and (min-width: 768px){
    .recoveryForm{
        padding: 50px 50px;
        margin: 0px 20px;
    }

    .profileFlex{
        flex-basis: 50%;
    }

    .profileFlex:nth-child(2){
        padding-left: 10px;
    }
    .profileFlex .profileInput{
        flex-grow: 1;
    }
    .profileFlex span{
        flex-basis: 20%;
    }
}
@media screen and (min-width: 1024px){
    .recoveryForm{
        margin: 0px 10%;
    }
}
