.footerWrapper {
  display: flex;
  justify-content: flex-end;
  background-color: var(--footer);
  color: white;
  font-family: "Poppins", sans-serif;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  margin-right: auto;
}

.slogan {
  font-size: 25px;
}

.slogan span {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}

.slogan :last-child {
  color: #aec400;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 35px;
}

.link {
  color: #d9dbe1
}

.link:hover {
  color: #aec400;
}

.nameColumn {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.images {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.image {
  width: 40px;
}

.email {
  color: #d9dbe1;
}

.email:hover {
  color: #aec400;
}

.copyright {
  margin-top: 10px;
}

@media screen and (max-width: 1024px) {
  .column {
    padding: 30px;
  }

  .info {
    padding: 30px;
  }
}

@media screen and (max-width: 830px) {
  .footerWrapper {
    flex-direction: column;
  }

  .column {
    align-items: center;
    padding: 10px;
  }

  .image {
    width: 25px;
  }

  .email {
    padding-bottom: 15px;
  }

  .nameColumn {
    font-weight: 500;
  }

  .info {
    padding: 15px 0 0 0;
    align-items: center;
    margin-right: 0;
  }
}
