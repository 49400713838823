.tableImgMobile {
    height: 88px;
    border-radius: 50%;
    overflow: hidden;
}

.treeTableRowMobile {
    display: flex;
    border: 1px solid black;
    padding: 10px;
    color: #000;
}

.treeTableRowMobile:hover {
    color: #000;
    background-color: #d9dbe1;
}

.treeTableRowMobile + .treeTableRowMobile {
    border-top: 0;
}

.treeTableMobile {
    display: none;
    font-family: "Poppins", sans-serif;
}

.treeTableItemWrapperHeadingMobile,
.treeTableItemWrapperMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
}

.treeTableItemWrapperHeadingMobile {
    flex-grow: 1;
    margin-left: 16px;
}


a:hover {
    text-decoration: none;
}

.treeTableHeader {
    display: none;
}

.treeTableBody {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.treeTableItemWrapper {
    width: 100%;
    padding: 20px 20px 15px 20px;
    border-radius: 5px;
    margin-bottom: 25px;
    max-width: 300px;
}

.treeTableItem {
    margin-bottom: 5px;
    word-break: break-word;
    word-wrap: break-word;
}

.treeTableItemImg,
.treeTablePencil {
    display: block;
    text-align: center;
}

.treeTableItemImg {
    width: 100%;
    text-align: center;
}

.tableImg {
    max-width: 100%;
    max-height: 197px;
}

.treeNavigation {
    text-align: center;
    margin: 30px 0;
}

.treeTableItemWrapper {
    max-width: 195px;
    flex-basis: 195px;
    width: 100%;
    margin: 0 1% 25px 1%;
    padding-bottom: 70px;
    position: relative;
}

.tableImg {
    max-height: 130px
}

.treeTablePencil {
    position: absolute;
    bottom: 15px;
    width: 155px;
}

.treeTable {
    display: table;
    border-collapse: collapse;
    width: 100%;
}

.treeTableHeader {
    display: table-row;
    border: 1px black solid;
}

.treeTableItemWrapper {
    box-shadow: none;
    display: table-row;
    height: 70px;
    margin: 0;
    padding: 0;
}

.treeTableItemWrapper:hover {
    cursor: pointer;
    background-color: #d9dbe1;
}

.treeTablePrefix {
    display: none;
}

.treeTableBody {
    display: table-row-group;
}

.treeTableHeaderItem {
    display: table-cell;
    text-align: center;
    font-weight: bold;
    padding: 20px;
    vertical-align: middle;
    border: 1px solid black;
}

.treeTableItem {
    display: table-cell;
    text-align: center;
    padding: 5px 20px;
    color: black;
    vertical-align: middle;
    font-size: 18px;
}

.treeTableItemImg {
    width: auto;
}

.tableImg {
    max-height: 70px;
    max-width: 70px;
}

.emptyTable{
  text-align: center;
  font-size: 20px;
  height: 100%;
}


@media screen and (max-width: 600px) {
    .treeTableMobile {
        display: block
    }

    .treeTable {
        display: none;
    }
}

.container {
    margin-bottom: 40px;
}
