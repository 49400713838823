.active {
  border-bottom: 3px solid #fb8f07;
  color: #fb8f07;
}

.welcome {
  background-color: var(--dark-green-black);
  text-align: center;
}

.smallHeader {
  font-size: 20px;
  display: block;
}

.bigHeader {
  font-size: 40px;
  font-family: "Merriweather", serif;
  color: white;
  font-weight: 400;
  padding-top: 20px;
}

.welcome .title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: white;
  padding: 20px 0;
}

.welcome p {
  font-family: "Recursive", sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 80%;
  display: block;
  margin: 0 auto;
  padding: 10px 0;
}

.links {
  display: flex;
}

.btnLogin,
.btnSignUp {
  width: 50%;
  height: 4em;
  border: none;
  font-family: "Recursive", sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #aec400;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  border-bottom: 3px solid #aec400;
}

@media screen and (min-width: 768px) {
  .welcome {
    display: none;
  }
}
