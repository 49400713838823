* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  height: 100%;
}


@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"), url("./fonts/Merriweather-Regular.ttf");
}

@font-face {
  font-family: "Recursive";
  src: local("Recursive"), url("./fonts/Recursive-Regular.ttf");
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"), url("./fonts/Oswald-Regular.ttf");
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark-green-black: #02390B;
  --dark-green-dark-grey: #02390B;
  --dark-green-white: #02390B;
  --dark-green-light-green: #02390B;
  --light-grey-black: #EEF0F2;
  --dark-gray-black: #000000;
  --white-black: #FFFFFF;
  --black-white: #000000;
  --white-dark-grey: #FFFFFF;
  --white-light-green: #FFFFFF;
  --footer: #02390B;
  --login-form: #FFFFFF;
  --background-color: #FFFFFF;
}

[data-theme='dark'] {
  --dark-green-black: #000000;
  --dark-green-dark-grey: #2C2C2C;
  --dark-green-white: #FFFFFF;
  --light-grey-black: #000000;
  --dark-gray-black: #2C2C2C;
  --white-black: #000000;
  --black-white: #FFFFFF;
  --white-dark-grey: #2C2C2C;
  --white-light-green: #AEC400;
  --dark-green-light-green: #AEC400;
  --footer: #232624;
  --login-form: rgba(0, 0, 0, 0.3);
  --background-color: #2c2c2c;
}

/* Hide arrows from MUI TextField*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
