.header {
    margin-bottom: 60px;
    padding-top: 25px;
    display: flex;
    align-items: center;
    font-size: 35px;
    font-weight: bold;
    color: rgba(2, 57, 11, 1);

    position: relative;
    justify-content: center;
    height: 105px;
}

.headerSplit {
    clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0 100%);
    height: 80px;
    background-color: rgba(2, 57, 11, 1);
    width: calc(45% - 250px);
    min-width: 65px;

    left: 0;
    position: absolute;
}

/*@media screen and (max-width: 1200px) {*/
/*    .headerSplit {*/
/*        width: 25%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 800px) {*/
/*    .headerSplit {*/
/*        width: 20%;*/
/*    }*/
/*}*/

@media screen and (max-width: 600px) {
    .header {
        font-size: 23px;
        height: 85px;
    }

    .headerSplit {
        display: none;
    }
}
