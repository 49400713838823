.mapButtonContainer {
    height: 40px;
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 50px;
}

@media screen and (max-width: 640px) {
    .mapButtonContainer {
        left: 0;
        justify-content: center
    }
}

.mapButtonSecondary {
    background-color: rgb(174, 43, 43);
    color: white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.05em;
    height: 48px;
    border: 0;
    border-radius: 24px;
    padding: 5px 30px;
    margin-right: 15px;
}

.mapButtonSecondary:hover{
    background-color: rgb(211, 23, 23);
}

.mapButtonSecondary:disabled{
    background-color: #b9c5b0;
}

.mapButtonSuccess {
    background-color: #02390B;
    height: 48px;
    border:0;
    color: white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.05em;
    border-radius: 24px;
    padding: 0 30px;
}

.mapButtonSuccess:hover{
  background-color: #03480c;
}

.mapButtonSuccess:disabled{
    background-color: #b9c5b0;
}
