.profileHeading {
    text-align: center;
    padding: 20px 0;
}

.profileForm {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    margin: 10px;
    padding: 20px;
    background-color: white;

}

.profileInput {
    height: 35px;
    border: 1px solid grey;
    padding: 0 10px;
    min-width: 60%;
    resize: none;
    line-height: 45px;
}

.flex-column{
    flex-direction: column;
}
.flex-column span{
    line-height: 35px;
}
.flex-column .profileInput{
    width: 100%;
    line-height: 35px;
}
.textInfo{
    height: 50px;
}
.profileFlex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}
.profileFlex span{
    flex-basis: 10%;
}
.profileControls{
    display: flex;
    justify-content: space-around;
    padding: 30px 10px;
}
.profileBtn{
    border: 0;
    padding: 10px 15px;
    border-radius: 24px;
    background: #50802C;
    color: white;
    font-size: 18px;
}
.profileBtn:hover{
    filter: brightness(80%);
}

.dimOnHover:hover{
    filter: brightness(80%);
}

.profileChangePasswordContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media screen and (min-width: 768px) {
    .profile-photo{
        flex-grow: 1;
        max-width: unset;
    }
    .profileForm{
        border-radius: 5px;
        margin: 0 10%;
    }
    .profileInput{
        flex-grow: 1;
        height: 45px;
    }
    .profileGroup{
        display: flex;
        justify-content: space-between;
        order: 0;
    }
    .profileGroup .profileFlex{
        flex-basis: 50%;
    }
    .profileGroup .profileFlex:nth-child(2){
        padding-left: 10px;
    }
    .profileGroup .profileFlex .profileInput{
        flex-grow: 1;
    }
    .profileGroup .profileFlex span{
        flex-basis: 20%;
    }
    .order1{
        order: 1;
    }
    .order2{
        order: 2;
    }
    .order3{
        order: 3;
    }
}
