
.navigation {
  max-width: 1500px;
  display: flex;
  width: 95%;
  justify-content: space-evenly;
  margin: 40px auto;
}

.nextPage,
.prevPage {
  width: 150px;
  height: 40px;
  background: #02390b;
  border: 0;
  color: #fff;
}

.nextPage:not(.disabled):hover,
.prevPage:not(.disabled):hover {
    opacity: 0.8;
}

.disabled {
  opacity: 0.7;
}

.spinner {
    margin: 40px auto;
}