.form {
    margin: 55px auto 20px auto;
    width: 95%;
}

.subTitle,
.blockTitle {
    margin-top: 5px;
}

.mainTitle {
    font-size: 24px;
    color: #02390B;
    font-weight: 600;
}

.title {
    font-size: 24px;
    margin: 30px 0 0 30px;
    display: inline-block;
}

.blockWrapper {
    margin-top: 20px;
}

.block,
.filesWrapper,
.imagesWrapper {
    box-shadow: 0 0 15px -5px rgb(34 60 80 / 57%);
}

.blockTitle {
    margin-bottom: 5px;
}

.buttons {
    margin-top: 43px;
    display: flex;
    padding: 20px 36px;
    background-color: transparent;
    justify-content: space-around;
}

.addButton,
.cancelButton {
    border: 1px solid #02390B;
    border-radius: 24px;
    background-color: #02390B;
    color: white;
    height: 48px;
    width: 40%;
    max-width: 427px;
    font-size: 20px;
    font-weight: 600;
}

.cancelButton {
  color: #02390B;
  border: 2px solid #02390B;
  background-color: transparent;
}

.cancelButton:hover {
  border: 3px solid #02390B;
}

.addButton:hover {
  opacity: .6;
}

.block {
    background-color: #fff;
    padding: 15px 15px 20px 15px;
    margin-bottom: 15px;
    border-left: 38px solid #02390B;
}

@media screen and (max-width: 640px) {
    .block {
        border-left-width: 15px;
    }
}

.blockPrefix {
    display: block;
    font-size: 16px;
    width: 100%;
    margin: 5px 0;
}

.blockValue {
    text-align: left;
    margin: 0;
    width: 100%;
    font-size: 16px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    height: 35px;
}

.blockSelect {
    text-align-last: left;
    width: 100%;
    font-size: 16px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    height: 35px;
}

.blockSelectOption {
    text-align: left;
}

.blockFileItem {
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
}

.errorMessage {
    color: red;
    font-size: 25px;
    font-weight: 500;
}


.geopositionWrapper {
    width: 100%;
    line-height: 21px;
}

.geopositionItem {
    width: 100%;
    display: block;
}

@media screen and (min-width: 550px) {
    .blockWrapper {
        position: relative;
    }


    .buttons {
        margin-top: 43px;
        display: flex;
        padding: 20px 36px;
    }

    .blockWrapper:first-child {
        margin-top: 0;
    }

    .wrapperFlex {
        margin-top: 20px;
    }

    .blockPrefix {
        display: inline-block;
        width: 30%;
    }

    .blockValue,
    .blockSelect {
        position: absolute;
        width: 68%;
        top: 0;
        right: 0;
    }


    .geopositionWrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 68%;
        display: inline-block;
    }

    .geopositionItem {
        width: 50%;
    }
}

.block {
    background-color: #fff;
}

.filesWrapper,
.imagesWrapper {
    border-left: 38px solid #02390B;
}

@media screen and (max-width: 640px) {
    .imagesWrapper,
    .filesWrapper {
        border-left-width: 15px;
    }
}

@media screen and (min-width: 768px) {
    .blockTitle {
        font-size: 20px;
    }

    .filesWrapper,
    .imagesWrapper {
        margin-top: 42px;
    }

    .subTitle {
        color: #4b4b4b;
    }

    .form {
        /* max-width: 1150px; */
        width: 90%;
        margin: 20px auto 20px auto;
    }

    .wrapperFlex {
        margin-top: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .block {
        padding: 25px 30px 30px 30px;
    }

    .blockPrefix {
        color: #4b4b4b;
        max-width: 215px;
        width: 100%;
    }

    .blockWrapperDesktop {
        width: 49%;
    }

    .blockWrapperDesktop:first-child {
        margin-top: 20px;
    }

    .blockWrapperDesktop:nth-child(2n) {
        margin-left: 2%;
    }

    .blockSelect,
    .blockValue {
        position: static;
        width: 77.5%;
    }

    .blockWrapperDesktop .blockValue,
    .blockWrapperDesktop .blockSelect {
        width: 54%;
    }

    .geopositionWrapper {
        position: static;
    }

    .blockWrapper {
        display: flex;
    }
}

@media screen and (max-width: 1024px) {
    .addButton,
    .cancelButton {
        width: 45%;
    }
}
