.volunteers {
  background-color: var(--white-dark-grey);
  display: flex;
}

.startTextWrapper {

}

.wrapper {
  z-index: 1000;
  width: 473px;
  flex-shrink: 0;
  position: relative;
  min-height: 150px;
}

.startInfo {
  display: flex;
  flex-direction: row;
  background-color: var(--light-grey-black);
}

.popup {
  background-color: #02390b;
  color: white;
  font-size: 20px;
  border-radius: 10px;
  width: 480px;
  z-index: 2;
  flex-shrink: 0;
  position: absolute;
  bottom: 15px;
  left: 50px;
}

.popupText {
  text-align: center;
  display: inline-block;
  margin: 0 30px 30px 30px;
}

.popupTitle {
  text-transform: uppercase;
  display: block;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  margin: 30px auto 10px auto;
}

.startWrapper {
  margin-left: 100px;
  padding: 15px 0;
  margin-right: 50px;
}

.startTitle,
.startText,
.startLink {
  color: var(--black-white);
}

.startTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 10px;
}

.startText {
  font-size: 21px;
  margin-right: 10px;
}

.startLink {
  text-decoration: underline;
  text-underline-position: under;
  font-size: 21px;
  margin-right: 15px;
}

.startLink:hover {
  color: #aec400;
}

.weDoBlock {
  background-color: var(--light-grey-black);
  padding-bottom: 80px;
}

.weDoHeader {
  font-size: 35px;
  font-weight: 700;
  color: var(--dark-green-light-green);
  display: inline-block;
  margin: 30px 0 0 66px;
  text-transform: uppercase;
}

.weDoInner {
  font-size: 20px;
  margin: 25px 50px 0 50px;
  background-color: white;
  color: white;
  display: flex;
}

.weDoContent {
  padding: 16px;
  color: black;
  margin: 0;
}

.login,
.signup {
  height: 60px;
  min-width: 200px;

  border-radius: 5px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  justify-content: center;
  display: flex;
  align-items: center;
}

.login {
  background-color: var(--button-login);
  color: white;
  font-weight: 600;
}

.signup {
  margin-left: 15px;
  background-color: var(--button-signup);
  border: 1px solid;
  border-color: var(--button-login);
  color: var(--button-login);
  font-weight: 300;
  padding-left: 20px;
  padding-right: 20px;
}

.voluntaryWrapper {
  padding: 75px 50px 75px 75px;
  display: flex;
  flex-direction: column;
  color: var(--black-white-text);
}

.voluntaryQuestion {
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dark-green-light-green);
  margin-bottom: 20px;
}

.voluntaryImage {
  height: 400px;
  width: 400px;
  border-radius: 50%;
  margin: 50px 0 50px 50px;
}

.voluntaryText {
  color: var(--black-white);
  font-family: "Recursive", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.weDoContent ul {
  padding-left: 50px;
}

@media screen and (max-width: 1024px) {
  .startInfo {
    flex-direction: column;
    align-items: center;
  }

  .startWrapper {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .popup {
    left: 0;
  }

  .volunteers {
    flex-direction: column;
    align-items: center;
  }

  .voluntaryImage {
    margin: 25px 0;
    padding: 0 20px;
    max-width: 100%;
    height: inherit;
  }

  .voluntaryWrapper {
    padding: 0 20px;
  }

  .voluntaryQuestion {
    font-size: 25px;
    text-align: center;
  }
}

@media screen and (max-width: 580px) {
  .popup {
    width: 90vw;
    height: fit-content;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0);
  }

  .popupText {
    font-size: 15px;
  }

  .popupTitle {
    font-size: 18px;
  }

  .startTextWrapper {
    flex-direction: column;
  }

  .wrapper {
    width: 100vw;
    left: 0;
  }

  .startWrapper {
    text-align: left;
    margin-left: 14px;
    top: 24.5px;
  }

  .voluntaryImage {
    /*width: 70%;*/
  }

  .startTitle {
    font-size: 22px;
    line-height: 28px;
  }

  .startLink {
    margin-left: auto;
    margin-right: 10px;
  }


  .startButton {
    display: block;
    left: 0;
    margin: 0 auto;
    z-index: 3;
    top: 24.5px;
  }
}

@media screen and (max-width: 480px) {
  .weDoImage {
    aspect-ratio: 1;
    width: 100%;
    padding: 10px;
  }

  .weDoInner {
    flex-direction: column-reverse;
    font-size: 20px;
    margin: 25px 25px 0;
  }

  .weDoHeader {
    font-size: 23px;
    margin: 30px 0 0 20px;
  }

  .weDoContent {
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .weDoContent ul {
    padding-left: 25px;
  }

  .weDoInner {
    margin: 25px 25px 0;
  }
}

.map {
  height: calc(100vh - 300px);
}

@media screen and (min-width: 1024px) {
  .map {
    height: calc(100vh - 276px)
  }
}

.videoContent {
  background-color: var(--white-dark-grey);
  display: flex;
  justify-content: center;
  padding: 0;
  flex-direction: column;
}

.videoHeader {
  font-size: 35px;
  font-weight: 700;
  color: var(--dark-green-light-green);
  display: inline-block;
  margin: 30px 0 0 66px;
  text-transform: uppercase;
}

.video {
  margin: 25px 50px 80px 50px;
  display: flex;
  justify-content: center;
}

.video iframe {
  height: 700px;
  width: 100%;
}
