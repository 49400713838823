* {
  box-sizing: border-box;
}

body {
  background-color: var(--background-color) !important;
}


.wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  padding: 10% 0 0;
}

.loginContainer {
  box-shadow: 0px 0px 15px -5px rgba(34, 60, 80, 0.57);
  background-color: var(--login-form);
  display: flex;
  margin: 40px auto;
}

.wrapper input {
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  border-radius: 25px;
  border: 1px solid rgb(175, 175, 175);
  height: 35px;
  margin-bottom: 15px;
  padding: 0px 24px;
}

.wrapper input:focus {
  outline: none;
  border: 2px solid rgb(104, 103, 103);
}

.wrapper button {
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  border-radius: 25px;
  height: 50px;
  border: none;
  color: white;
  text-transform: uppercase;
  background-color: var(--dark-green-black);
  cursor: pointer;
  margin: 15px 0px;
}

.wrapper button:hover,
.wrapper button:active {
  background-color: #4c7929;
}

.wrapper .loginMessage {
  color: var(--black-white);
  text-align: center;
  margin: 0;
  font-size: 14px;
}

.wrapper .flex {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 15px auto;
}

.flexRegister {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.flexRegister p {
  font-weight: 500;
  font-size: 16px;
  color: #AEC400;
}

.flexRegister a {
  padding-left: 3px;
}

.flexRegister a:hover {
  color: #1d53aa;
}

.privacyTerms {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  padding: 15px 0;
}

.loginHelp {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  font-size: 14px;
}

.login-checkbox {
  position: relative;
  color: #aec400;
  padding-left: 30px;
  cursor: pointer;
}

.login-checkbox:hover,
.login-checkbox:focus {
  color: #aec400;
}

.login-checkbox input[type="checkbox"]+.checkbox-indicator {
  position: absolute;
  top: -2px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #000000;
}

.login-checkbox input[type="checkbox"]:checked+.checkbox-indicator::before {
  content: "\f00c";
  font-family: "FontAwesome";
  position: relative;
  left: 1px;
  color: black;
}

.restore {
  color: #AEC400;
  text-decoration: none;
  font-size: 14px;
}

.restore:hover,
.restore:focus {
  text-decoration: none;
}

.visually-hidden {
  display: none;
}

.social a {
  text-align: center;
  width: 40px;
  height: 40px;
  float: left;
  margin: 0 10px 10px 0;
}

.social a img {
  width: 100%;
  height: 100%;
}

.title {
  display: none;
  color: var(--dark-green-white);
  font-size: 35px;
  text-align: center;
  padding: 30px 0px;
  font-weight: 700;
}

.loginAside {
  display: none;
  flex-basis: 50%;
  background-color: var(--dark-green-black);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
}

.loginAside .title {
  color: white;
  font-size: 35px;
}

.loginAside p {
  padding: 10px 0px;
  color: #AEC400;
}

.flexSocial {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.loginAside .linkRegister {
  width: 200px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
  color: var(--white-light-green);
  font-size: 12px;
  background-color: var(--dark-green--dark-grey);
  border: 1px solid var(--white-light-green);
  border-radius: 5px;
  margin: 10px 0px;
}

.loginAside .linkRegister:hover,
.loginAside .linkRegister:active {
  background-color: white;
  color: #4f802c;
  transition: ease-in 0.15s;
}

.loginMessage {
  height: 40px;
  padding: 10px 0;
}

.loginMessage p {
  color: red;
}

@media screen and (min-width: 1024px) {
  .loginContainer {
    height: 550px;
    width: 80%;
    max-width: 1220px;
    margin: 40px auto;
  }

  .loginHelp {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .loginAside {
    display: flex;
  }

  .title {
    display: inline;
  }

  .wrapper {
    flex-basis: 50%;
    padding: 0px 70px;
  }

  .privacyTerms {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .loginContainer {
    margin: 0;
  }
}