.navBar {
    background: #333333;
}

.visibleMenuLink{
    color: white;
    display: block;
    padding: 20px 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 3px solid #333;
}

.active {
    border-bottom: 3px solid #aec400;
    color: #aec400;
}

.visibleMenuLink:hover{
    background: white;
    color: #333333;
}

.invisibleMenuLink {
    display: none;
}
