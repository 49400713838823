.main {
  padding: 0 40px;
  margin-bottom: 40px;
}

.main__title {
  color: #50802C;
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 40px;
}

.main__image {
  float: right;
  max-height: 400px;
  padding: 10px;
}

.main__text {
  font-size: 20px;
  line-height: 30px;
}

.main__title {
  font-size: 40px;
}

@media screen and (max-width: 1024px) {
  .main__title {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {

  /* .main__image {
    float: none;
    width: 100%;
  } */
  .main{
    padding: 0 20px;
  }
}

@media screen and (max-width: 550px) {
  .main__image {
    float: none;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .main__title {
    font-size: 23px;
  }
}