.comment{
  margin: 5px 20px;
}

.container {
    max-width: 1500px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 25px;
}

.wrapper {
    background: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    padding: 15px 0;
    border-left: 38px solid #02390B;
}

@media screen and (max-width: 640px) {
    .wrapper {
        border-left-width: 15px;
    }
}

.title {
    font-size: 24px;
    margin-left: 20px;
    margin-bottom: 35px;
}

.editLinkWrapper {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 38px;
    background: #02390B;
    justify-content: space-between;
}

.editLinks {
    display: flex;
}

@media (max-width: 640px) {
  .editLinkWrapper {
    padding: 10px 15px;
  }
}

.editLink,
.removeLink,
.approveButton {
    border-radius: 24px;
    font-size: 18px;
    cursor: pointer;
    color: white;
    padding: 10px 20px;
    line-height: 18px;
    text-align: center;
}

.editLink,
.approveButton {
    background-color: #aec400;
}

.editLink {
    margin-right: 20px;
}

.editLink:hover,
.approveButton:hover {
    color: white;
}

.removeLink {
    background-color: #c40000;
}

@media (max-width: 640px) {
  .editLink,
  .removeLink,
  .approveButton {
    padding: 10px 10px;
  }
}

.editLink:hover,
.approveButton:hover,
.removeLink:hover {
    opacity: 0.8;
}

.tbody {
    display: flex;
    flex-wrap: wrap;
    margin: 0 20px;
    column-gap: 15px;
    row-gap: 6px;
}

.row {
    display: flex;
    width: 49%;
}

.col {
    font-weight: 500;
}

.col:nth-child(1) {
    flex-grow: 1;
    font-weight: 600;
    min-width: 40%;
}

.col:nth-child(2) {
    text-align: end;
}

.approved {
    font-size: 18px;
    color: white;
    padding: 10px 0;
    line-height: 18px;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .tbody {
        flex-direction: column;
        margin: 0 20px;
    }

    .row {
        width: 100%;
    }

    .title {
        margin-left: 20px;
    }

}

@media screen and (max-width: 530px) {
    .editLinkWrapper {
        flex-direction: column;
    }

    .approveButton,
    .approved {
        margin-bottom: 20px;
    }

    .editLink {
        flex-grow: 1;
    }
}
