.treeFormContainer {
    background-color: rgba(0,0,0,0);
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    /*pointer-events: none;*/
}

/*.active{*/
/*    z-index: 1000;*/
/*    opacity: 1;*/
/*    pointer-events: all;*/
/*}*/
