.userContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.user {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-heading {
    font-size: 18px;
}

.userName,
.userRole {

    display: block;
    color: white;
}

.userName {
    font-weight: 600;
}

.userRole {
    font-size: 12px;
    line-height: 12px;
}

.userIcon {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    flex: 0;
}

.userBtn {
    cursor: pointer;
    background: none;
    border: 0;
    outline: none;
}

.faSignOut {
    color: lightgrey;
    font-size: 32px;
    cursor: pointer;
    margin-left: 16px;
}

.faSignOut:hover {
    color: #aec400;
}

.faChevronDownActive {
    color: rgb(84, 171, 253);
}

.faSignOut:before {
    content: "\f08b";
}

.userControls {
    display: flex;
    justify-content: space-between;
    flex-basis: 60px;
}

@media screen and (min-width: 320px) {
    .container {
        margin: 10px;
    }
}

@media screen and (min-width: 768px) {
    .container {
        margin: 0;
    }

    .userContainer {
        /*margin: 0px 10%;*/
    }
}
