.hr {
    border: none;
    background-color: #aec400;
    height: 2px;
    margin: 0;
}

.hr + .row {
    margin-top: 30px;
}

.leftBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.rightBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 25px;
    min-width: 195px;
}

.row {
    font-size: 17px;
    font-weight: bold;
    display: flex;
}

.rowName {
    flex-grow: 1;
    margin-right: 10px;
}

.link {
    pointer-events: all;
    padding: 10px 0;
    background-color: black;
    color: #fff !important;
    font-size: 16px;
    text-align: center;
    width: 100%;
    border-radius: 10px;
}

.link:hover,
.link:focus,
.link:active {
    color: #aec400 !important;
}

.wrapper {
    flex-grow: 1;
    width: 100%;
}

[class*="spinner"] {
    margin-top: 30px;
}


[class^="image"] {
    border: 2px solid white;
    aspect-ratio: 1.15;
    margin: 0 auto;
}

.mockImage {
  width: 100%;
  max-width: 170px;
  margin: 0 auto;
  border: 2px solid white;
  height: 170px;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
}

.form {
    margin: 20px 0;
}

.container {
    padding: 0 9px;
}

.title {
    margin-bottom: 10px;
    text-align: center;
    font-size: 25px;
}

.close {
    pointer-events: all;
    color: white;
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    background: none;
    font-size: 18px;
    border: 0;
    outline: none;
}

.blockTitle {
    margin-bottom: 5px;
}

.block {
    display: flex;
    justify-content: flex-end;
    background-color: #02390B;
    border: 1px solid #AEC400;
    color: white;
    border-radius: 10px;
    padding: 20px 20px 30px;
    z-index: 1000;
    margin: 0 15px;
    position: relative;
    max-width: 820px;
}

.close i {
    font-size: 32px;
}

.blockPrefix {
    display: block;
    font-size: 16px;
    width: 100%;
    margin: 5px 0;
}

.blockValue {
    text-align: left;
    margin: 0;
    width: 100%;
    font-size: 16px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    height: 35px;
}

.blockSelect {
    text-align-last: left;
    width: 100%;
    font-size: 16px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    height: 35px;
}

.blockSelectOption {
    text-align: left;
}

.blockFileItem {
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
}

.closeWrapper {
    width: 100%;
    text-align: right;
}

.addButton,
.cancelButton {
    color: white;
    cursor: pointer;
    font-size: 18px;
    display: block;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    height: 60px;
}

.addButton {
    background-color: #50802c;
    border-color: #50802c;
    margin-bottom: 15px;
}

.addButton:hover,
.addButton:focus,
.addButton:active {
    opacity: .6;
}

.cancelButton {
    background-color: #757575;
    border-color: #757575;
}

.cancelButton:hover,
.cancelButton:focus,
.cancelButton:active {
    opacity: .6;
}

.geopositionWrapper {
    width: 100%;
    line-height: 21px;
}

.geopositionItem {
    width: 100%;
    display: block;
    font-size: 16px;
}

@media screen and (max-width: 550px) {
    .row {
        font-size: 16px;
    }

    .block {
        flex-direction: column;
        padding: 20px;
        z-index: 9999;
    }

    .rightBlock{
      margin-top: 5px;
    }

    [class^="image"] {
        height: 130px;
        margin: 5px auto;
    }
}

@media screen and (min-width: 768px) {
    .title {
        font-size: 25px;
    }

    .blockTitle {
        font-size: 20px;
    }

    .subTitle {
        color: #4b4b4b;
    }

    .navigation {
        display: flex;
    }

    .blockWrapper:first-child {
        margin-top: 20px;
    }

    .form {
        max-width: 1150px;
        width: 100%;
        margin: 20px auto 20px auto;
    }

    .wrapperFlex {
        margin-top: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .blockPrefix {
        color: #4b4b4b;
        max-width: 215px;
        width: 100%;
    }

    .addButton,
    .cancelButton {
        width: 50%;
    }

    .blockWrapperDesktop {
        width: 49%;
        margin-top: 20px;
    }

    .blockWrapperDesktop:nth-child(2n) {
        margin-left: 2%;
    }

    .blockSelect,
    .blockValue {
        position: static;
        width: 77.5%;
    }

    .blockWrapperDesktop .blockValue,
    .wrapperDesktop .blockSelect {
        width: 54%;
    }

    .geopositionWrapper {
        position: static;
        margin-bottom: 20px;
    }

    .blockWrapper {
        display: flex;
    }

    .blockWrapper:first-child {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 768px) {
    .block {
        width: 100%;
    }
  .close i {
    font-size: 17px;
  }
}
